var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "elevation-1 my-7",
    class: {
      'on-add': _vm.onAddingProcess
    },
    attrs: {
      "headers": _vm.headers,
      "items": _vm.data,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("System Objects")]), _c('v-spacer'), _c('v-dialog', {
          attrs: {
            "max-width": "900px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "accent",
                  "dark": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" New Item ")])];
            }
          }]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        }, [_c('v-card', [_c('v-card-title', [_c('span', {
          staticClass: "text-h5"
        }, [_vm._v(_vm._s(_vm.formTitle))])]), _c('v-card-text', [_c('v-data-table', {
          staticClass: "elevation-1",
          attrs: {
            "headers": _vm.allSystemObjects,
            "items": _vm.systemObjects,
            "item-key": "id",
            "show-select": "",
            "data-table-border-radius": "20"
          },
          model: {
            value: _vm.selectedSystems,
            callback: function ($$v) {
              _vm.selectedSystems = $$v;
            },
            expression: "selectedSystems"
          }
        })], 1), _c('v-card-actions', {
          staticClass: "pr-8 pb-8"
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "accent",
            "disabled": _vm.isSaveButtonDisabled
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" Save ")])], 1)], 1)], 1), _c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v;
            },
            expression: "dialogDelete"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v("Are you sure you want to delete this item?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.closeDelete
          }
        }, [_vm._v("Cancel")]), _c('v-btn', {
          attrs: {
            "color": "accent"
          },
          on: {
            "click": _vm.deleteItemConfirm
          }
        }, [_vm._v("OK")]), _c('v-spacer')], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: `item.actions`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }, {
      key: "no-data",
      fn: function () {
        return undefined;
      },
      proxy: true
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }