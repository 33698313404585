<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    class="elevation-1 my-7"
    :class="{ 'on-add': onAddingProcess }">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>System Objects</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on"> New Item </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-data-table
                v-model="selectedSystems"
                :headers="allSystemObjects"
                :items="systemObjects"
                item-key="id"
                show-select
                class="elevation-1"
                data-table-border-radius="20">
              </v-data-table>
            </v-card-text>

            <v-card-actions class="pr-8 pb-8">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close"> Cancel </v-btn>
              <v-btn color="accent" :disabled="isSaveButtonDisabled" @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
              <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small color="primary" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <!--<v-btn color="primary" @click="initialize">
        Reset
      </v-btn>-->
    </template>
  </v-data-table>
</template>

<script>
import editableTableMixin from "@/views/TenantManagement/mixins/editableTableMixin";

export default {
  mixins: [editableTableMixin],
  props: ["data", "loading", "systemObjects", "onAddingProcess"],
  data: () => ({
    selectedSystems: [],
    headers: [
      { text: "Provider", align: "start", sortable: false, value: "provider" },
      { text: "Category", value: "category" },
      { text: "Slug", value: "slug" },
      { text: "Max Delivery Days", value: "maxDeliveryDays" },
      { text: "Min Delivery Days", value: "minDeliveryDays" },
      { text: "Actions", value: "actions", sortable: false, align: "right" },
    ],
    editedItem: {
      environment: "",
      username: "",
      password: "",
    },
    defaultItem: {
      environment: "",
      username: "",
    },
  }),

  computed: {
    allSystemObjects() {
      return this.headers.filter((item) => item.value !== "actions");
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    isSaveButtonDisabled() {
      // is selected item already included in tenant's systems object !
      return this.selectedSystems.some(system => this.data.find(item => item.id === system.id)) // prettier-ignore
    },
  },
  methods: {
    save() {
      this.selectedSystems.forEach((system) => {
        this.data.push(system);
      });

      this.close();
    },
  },
};
</script>
