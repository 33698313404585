<template>
  <div class="mb-7">
    <v-expansion-panels :class="{ 'on-add': onAddingProcess }" accordion expansion-panel-border-radius="30">
      <!--General Informations Section-->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red"
          >General Informations
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row align="center">
            <v-col cols="4">
              <v-text-field
                :value="tenant.id"
                v-model="tenant.id"
                label="Tenant Id"
                dense
                hide-details="auto"
                outlined
                required></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.name"
                dense
                v-model="tenant.name"
                label="Name"
                hide-details="auto"
                outlined
                required></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.bookingAffiliateId"
                dense
                v-model="tenant.bookingAffiliateId"
                label="Booking Affiliate ID"
                hide-details="auto"
                outlined
                required></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.isOwnedByTrainPlanet"
                inset
                :label="'Is owned by Trainplanet'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.canUseGiftCards"
                inset
                :label="'Can Sell & Use Gift Cards'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.canSellJourneys"
                inset
                :label="'Can Sell Journeys'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.canSellRailPassSeatReservations"
                inset
                :label="'Can Sell Rail Pass Seat Reservations'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.canSellRailPasses"
                inset
                :label="'Can Sell Rail Passes'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.canSellTicketsByInvoice"
                inset
                :label="'Can Sell Tickets By Invoice'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.canSellTaxis"
                inset
                :label="'Can Sell Taxis'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.nationalIdNumberVerificationRequired"
                inset
                :label="'Requires National ID Verification'"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.useMainTenantsNameForToSInEPSPayload"
                inset
                label="Display Main Tenant's Name in Terms & Conditions for EPS"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                hide-details="auto"
                color="greeny"
                v-model="tenant.showNewsletterCheckboxInCheckout"
                inset
                :label="'Show Newsletter Checkbox In Checkout'"></v-switch>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Email Credentials Section-->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red"
          >Email Credentials
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                :value="tenant.emailCredentials.fromAddress"
                v-model="tenant.emailCredentials.fromAddress"
                label="From Address"
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                :value="tenant.emailCredentials.fromName"
                v-model="tenant.emailCredentials.fromName"
                label="From Name"
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                :value="tenant.emailCredentials.host"
                v-model="tenant.emailCredentials.host"
                label="Host"
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="tenant.emailCredentials.username"
                dense
                v-model="tenant.emailCredentials.username"
                label="Username"
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="tenant.emailCredentials.port"
                dense
                v-model.number="tenant.emailCredentials.port"
                label="Port"
                hide-details="auto"
                outlined
                type="number"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="tenant.emailCredentials.password"
                dense
                v-model="tenant.emailCredentials.password"
                label="Password"
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Theme Informations Section     -->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1 font-weight-bold mango-red">
          Theme Informations
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.mainColor"
                v-model="tenant.theme.mainColor"
                label="Main Color"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.secondaryColor"
                v-model="tenant.theme.secondaryColor"
                label="Secondary Color"
                dense
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.headerColor"
                v-model="tenant.theme.headerColor"
                label="Header Color"
                dense
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.headerTitleColor"
                v-model="tenant.theme.headerTitleColor"
                label="Header Title Color"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.titleColor"
                v-model="tenant.theme.titleColor"
                label="Title Color"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.titleBackgroundColor"
                v-model="tenant.theme.titleBackgroundColor"
                label="Title Background Color"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.mainColorSvgFilters"
                v-model="tenant.theme.mainColorSvgFilters"
                label="Main Color Svg Filters"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.secondaryColorSvgFilters"
                v-model="tenant.theme.secondaryColorSvgFilters"
                label="Secondary Color Svg Filters"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.mainPageUrl"
                v-model.trim="tenant.theme.mainPageUrl"
                label="Main Page URL"
                dense
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.shopUrl"
                v-model.trim="tenant.theme.shopUrl"
                label="Shop URL"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.customerPageUrl"
                v-model.trim="tenant.theme.customerPageUrl"
                label="Customer Page URL"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.supportNumber"
                v-model="tenant.theme.supportNumber"
                label="Support Number"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.supportEmail"
                v-model="tenant.theme.supportEmail"
                label="Support Email"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.termsAndConditionsPageUrl"
                v-model.trim="tenant.theme.termsAndConditionsPageUrl"
                label="ToS Page URL"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.logoUrl"
                v-model.trim="tenant.theme.logoUrl"
                label="Logo URL"
                dense
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.blackLogoUrl"
                v-model.trim="tenant.theme.blackLogoUrl"
                label="Black Logo URL"
                dense
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.logoWidth"
                v-model="tenant.theme.logoWidth"
                label="Logo Width"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.gtmId"
                v-model="tenant.theme.gtmId"
                label="GTM ID"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.loadingAnimationUrl"
                v-model.trim="tenant.theme.loadingAnimationUrl"
                label="Loading Animation URL"
                dense
                hide-details="auto"
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.footerLogoUrl"
                v-model.trim="tenant.theme.footerLogoUrl"
                label="Footer Logo URL"
                hide-details="auto"
                dense
                outlined></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.poweredByLogoUrl"
                v-model.trim="tenant.theme.poweredByLogoUrl"
                label="Powered By Logo URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.favIconUrl"
                v-model="tenant.theme.favIconUrl"
                label="Fav Icon URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.favIcon16Url"
                v-model="tenant.theme.favIcon16Url"
                label="Fav Icon 16x16 URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.favIcon32Url"
                v-model="tenant.theme.favIcon32Url"
                label="Fav Icon 32x32 URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.appleTouchIconUrl"
                v-model="tenant.theme.appleTouchIconUrl"
                label="Apple Touch Icon URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.maskIconUrl"
                v-model="tenant.theme.maskIconUrl"
                label="Mask Icon URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.webManifestUrl"
                v-model="tenant.theme.webManifestUrl"
                label="Web Manifest URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.browserConfigUrl"
                v-model="tenant.theme.browserConfigUrl"
                label="Browser Config URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.fontCssUrl"
                v-model="tenant.theme.fontCssUrl"
                label="Font CSS URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                :value="tenant.theme.giftCardBackgroundImageUrl"
                v-model.trim="tenant.theme.giftCardBackgroundImageUrl"
                label="Gift Card Background Image URL"
                hide-details="auto"
                outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                outlined
                class="align-stretch font-weight-bold"
                v-model="tenant.theme.languages"
                :items="locales"
                chips
                item-value="value"
                item-text="text"
                :menu-props="{ maxHeight: '400' }"
                label="Select languages"
                multiple
                hide-details
                :persistent-hint="false"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                outlined
                class="align-stretch font-weight-bold"
                v-model="tenant.theme.currencies"
                :items="currencies"
                chips
                item-value="id"
                item-text="name"
                :menu-props="{ maxHeight: '400' }"
                label="Select currencies"
                multiple
                hide-details
                :persistent-hint="false">
              </v-select>
            </v-col>
            <v-col class="mt-4" cols="4">
              <v-switch
                class="mt-5 ml-10"
                color="greeny"
                v-model="tenant.theme.showCurrencyPicker"
                inset
                :label="'Show Currency Picker'"
                hide-details="auto"
                dense
                outlined></v-switch>
            </v-col>
          </v-row>
          <div>
            <v-row>
              <!--TOOLBAR OPTIONS-->
              <v-col cols="2" class="mt-4">
                <h3>Toolbar Options</h3>
              </v-col>
              <v-col cols="10">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.enabled"
                  inset
                  label="Show Toolbar"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.showContactButton"
                  inset
                  :label="'Show Contact Button'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.showCustomerPageButton"
                  inset
                  :label="'Show Customer Page Button'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.showFunkaButton"
                  inset
                  :label="'Show Funka Button'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.showMailButton"
                  inset
                  :label="'Show Mail Button'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.showPhoneButton"
                  inset
                  :label="'Show Phone Button'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  color="greeny"
                  v-model="tenant.theme.toolbarOptions.showSearchButton"
                  inset
                  :label="'Show Search Button'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
            </v-row>
          </div>
          <!--Support Page-->
          <div>
            <v-row>
              <h3 class="ml-3 mt-6">Support Page</h3>
              <v-spacer></v-spacer>
              <v-btn @click="addItemsupport" class="text-none mr-3 mb-4 mt-5 third white--text">
                <v-icon left dark>mdi-playlist-plus</v-icon>
                <span>Add Item</span>
              </v-btn>
            </v-row>
            <v-row cols v-for="(supportPageUrl, index) in tenant.theme.supportPageUrls" :key="index">
              <v-col cols="12" lg="3" md="12" sm="12" xs="12">
                <v-select
                  class="align-stretch"
                  :items="locales"
                  v-model="supportPageUrl.locale"
                  label="Support Page language"
                  hide-details="auto"
                  item-value="value"
                  item-text="text"
                  outlined></v-select>
              </v-col>
              <v-col cols="12" lg="9" md="12" sm="12" xs="12">
                <v-text-field
                  class="mt-2"
                  :rules="[rules.required]"
                  v-model="supportPageUrl.url"
                  label="Support Page URL"
                  hide-details="auto"
                  dense
                  outlined>
                  <template v-slot:append-outer>
                    <v-btn color="red" class="mt-2 mr-lg-n3 mt-lg-n2" icon @click="removeItemsupport(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <!--Group Order-->
          <div>
            <v-row>
              <h3 class="ml-3 mt-6">Group Order</h3>
              <v-spacer></v-spacer>
              <v-btn @click="addItem" class="text-none mr-3 mb-4 mt-5 third white--text">
                <v-icon left dark>mdi-playlist-plus</v-icon>
                <span>Add Item</span>
              </v-btn>
            </v-row>
            <v-row cols v-for="(groupOrderLinks, index) in tenant.theme.groupOrderLinks" :key="index">
              <v-col cols="3">
                <v-select
                  :items="locales"
                  v-model="groupOrderLinks.locale"
                  label="Group Order Links language"
                  hide-details="auto"
                  item-value="value"
                  item-text="text"
                  outlined></v-select>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  class="mt-2"
                  :rules="[rules.required]"
                  v-model="groupOrderLinks.url"
                  label="Group Order Links URL"
                  hide-details="auto"
                  dense
                  outlined>
                  <template v-slot:append-outer>
                    <v-btn color="red" icon class="mt-2 mr-lg-n3 mt-lg-n2" @click="removeItem(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <div>
            <!--  Navigation Menu-->
            <v-row>
              <v-col cols="2">
                <h3 class="mt-3 mb-3">Navigation Menu</h3>
              </v-col>
              <v-col cols="10">
                <v-switch
                  class="mt-3 mb-3"
                  color="greeny"
                  v-model="tenant.theme.showNavigationMenu"
                  inset
                  :label="'Show Navigation Menu'"
                  hide-details="auto"
                  dense
                  outlined></v-switch>
              </v-col>
            </v-row>
            <div>
              <v-tabs class="mb-8" v-model="activeNavigationMenuIndex">
                <v-tab
                  class="ml-2"
                  v-for="(navigationMenu, navigationMenuIndex) in tenant.theme.navigationMenu"
                  :key="navigationMenuIndex">
                  {{ locales.length > 0 && locales.find((locale) => locale.value === navigationMenu.locale).text }}
                  <v-btn color="red" icon @click="removeNavigationMenu(navigationMenuIndex)">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-tab>
                <div class="mt-2">
                  <v-select
                    label="Add New Translation"
                    class="mb-4 ml-7 pa-0"
                    :items="locales | notIn(locales, tenant.theme.navigationMenu)"
                    hide-details="auto"
                    @change="addNavigationMenu"
                    item-value="value"
                    item-text="text"
                    style="max-width: max-content"
                    :persistent-hint="false"></v-select>
                </div>
                <v-tabs-items>
                  <v-row class="">
                    <h3 class="mt-7 ml-3">Items</h3>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="addNavigationMenuItem(activeNavigationMenuIndex)"
                      class="text-none mt-5 mb-3 mr-3 white--text"
                      color="accent">
                      <v-icon left dark>mdi-playlist-plus</v-icon>
                      <span class="mr-7 ml-9">Add Item</span>
                    </v-btn>
                  </v-row>
                  <div
                    class="mb-3"
                    v-for="(item, navigationMenuItemIndex) in tenant.theme.navigationMenu[activeNavigationMenuIndex]
                      .items"
                    :key="navigationMenuItemIndex">
                    <v-row>
                      <v-col cols="6" class=" ">
                        <v-text-field
                          class="mt-5"
                          :rules="[rules.required]"
                          v-model="item.title"
                          label="Item Title"
                          hide-details="auto"
                          dense
                          outlined></v-text-field>
                      </v-col>
                      <v-col cols="6" class="mt-5">
                        <v-text-field
                          class="  "
                          :rules="[rules.required]"
                          v-model="item.url"
                          label="Item URL"
                          hide-details="auto"
                          dense
                          outlined>
                          <template v-slot:append-outer>
                            <v-btn
                              class="mt-2 mr-lg-n3 mt-lg-n2"
                              color="red"
                              icon
                              @click="removeNavigationMenuItem(activeNavigationMenuIndex, navigationMenuItemIndex)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ml-8">
                      <h4 class="ml-15 mt-1">Child Items</h4>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="addNavigationMenuItemChildItem(activeNavigationMenuIndex, navigationMenuItemIndex)"
                        class="text-none pa-3 mr-3 mb-2 third white--text">
                        <v-icon left dark>mdi-playlist-plus</v-icon>
                        <span>Add Child Item</span>
                      </v-btn>
                    </v-row>
                    <v-row
                      class=" "
                      v-for="(childItem, navigationMenuItemChildItemIndex) in item.childItems"
                      :key="navigationMenuItemChildItemIndex">
                      <v-col cols="1" class="mb-1 pa-1"> </v-col>
                      <v-col cols="4" class="mb-1 ml-lg-n8 pa-1">
                        <v-text-field
                          class="  "
                          :rules="[rules.required]"
                          v-model="childItem.title"
                          label="Child Item Title"
                          hide-details="auto"
                          dense
                          outlined></v-text-field>
                      </v-col>
                      <v-col cols="7" class="mb-1 pa-1">
                        <v-text-field
                          class="ml-5"
                          :rules="[rules.required]"
                          v-model="childItem.url"
                          label="Child Item URL"
                          hide-details="auto"
                          dense
                          outlined>
                          <template v-slot:append-outer>
                            <v-btn
                              class="mt-lg-n2 mr-lg-n10"
                              color="red"
                              icon
                              @click="
                                removeNavigationMenuItemChildItem(
                                  activeNavigationMenuIndex,
                                  navigationMenuItemIndex,
                                  navigationMenuItemChildItemIndex
                                )
                              ">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-tabs-items>
              </v-tabs>
            </div>
          </div>
          <!-- Domains -->
          <div>
            <v-row>
              <h3 class="ml-3 mt-6 mr-3">Domains</h3>

              <v-tooltip top color="black" style="max-width: 10px">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <div style="max-width: 300px">
                  These domains are used to detect a tenant's identity by domain. There should be no 'https://', no
                  'www' or no ending '/' at the end. Example: 'trainplanet.com' (no quotes)
                </div>
              </v-tooltip>

              <v-spacer></v-spacer>
              <v-btn @click="addDomain" class="text-none mr-3 mb-4 mt-5 third white--text">
                <v-icon left dark>mdi-playlist-plus</v-icon>
                <span>Add Domain</span>
              </v-btn>
            </v-row>
            <v-row v-for="(domain, domainIndex) in tenant.theme.domains" :key="domainIndex">
              <v-col>
                <v-text-field
                  class="mt-2"
                  :rules="[rules.required]"
                  v-model="tenant.theme.domains[domainIndex]"
                  label="Domain URL"
                  hide-details="auto"
                  dense
                  outlined>
                  <template v-slot:append-outer>
                    <v-btn color="red" class="mt-2 mr-lg-n3 mt-lg-n2" icon @click="removeDomain(domainIndex)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: { Header, VueEditor },
  props: ["tenant", "onAddingProcess", "currencies", "locales", "emptyTranslation"],
  data: () => ({
    loading: false,
    activeNavigationMenuIndex: 0,
    rules: {
      required: (value) => !!value || "Field is required",
    },
  }),
  filters: {
    notIn(locales, navigationMenus) {
      navigationMenus = JSON.parse(JSON.stringify(navigationMenus));
      return locales.filter(
        (locale) => !navigationMenus.some((navigationMenu) => navigationMenu.locale === locale.value)
      );
    },
  },
  computed: {
    editing: {
      get() {
        return Object.keys(this.navigationMenu).length !== 0;
      },
      set(doing) {
        if (!doing) {
          this.$emit("item:cleared");
        }
      },
    },
  },
  methods: {
    removeItem(index) {
      this.tenant.theme.groupOrderLinks.splice(index, 1);
    },
    addItem() {
      this.tenant.theme.groupOrderLinks.push({
        locale: "",
        url: "",
      });
    },
    removeItemsupport(index) {
      this.tenant.theme.supportPageUrls.splice(index, 1);
    },
    addItemsupport() {
      this.tenant.theme.supportPageUrls.push({
        locale: "",
        url: "",
      });
    },
    addNavigationMenu(languages) {
      this.tenant.theme.navigationMenu.push({
        locale: languages,
        items: [
          {
            title: "",
            url: "",
            childItems: [
              {
                title: "",
                url: "",
              },
            ],
          },
        ],
      });
      // this.$refs.addItemnavigation.reset(); // Reset the select input
    },
    removeNavigationMenu(index) {
      this.tenant.theme.navigationMenu.splice(index, 1);
    },
    addNavigationMenuItem(parentIndex) {
      this.tenant.theme.navigationMenu[parentIndex].items.push({
        title: "",
        url: "",
        childItems: [
          {
            title: "",
            url: "",
          },
        ],
      });
    },
    removeNavigationMenuItem(parentIndex, index) {
      this.tenant.theme.navigationMenu[parentIndex].items.splice(index, 1);
    },
    addNavigationMenuItemChildItem(grandparentIndex, parentIndex) {
      this.tenant.theme.navigationMenu[grandparentIndex].items[parentIndex].childItems.push({
        title: "",
        url: "",
      });
    },
    removeNavigationMenuItemChildItem(grandparentIndex, parentIndex, index) {
      this.tenant.theme.navigationMenu[grandparentIndex].items[parentIndex].childItems.splice(index, 1);
    },
    addDomain() {
      this.tenant.theme.domains.push("");
    },
    removeDomain(index) {
      this.tenant.theme.domains.splice(index, 1);
    },
  },
};
</script>
