<template>
  <div>
    <h3 v-if="onAddingProcess" class="py-4">Add New Tenant</h3>
    <div>
      <v-btn
        v-if="!onAddingProcess"
        @click="addNewTenant"
        style="margin-right: 65px"
        fixed
        right
        bottom
        fab
        medium
        dark
        color="accent">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-else
        @click="
          onAddingProcess = false;
          selectedTenant = tenants[0].id;
          getTenantDetail();
        "
        style="margin-right: 65px"
        fixed
        right
        bottom
        fab
        medium
        dark
        color="red">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="my-2" @click="saveTenant()">
      <v-btn color="greeny" fixed right bottom fab medium dark>
        <v-icon>mdi-content-save-outline</v-icon>
      </v-btn>
    </div>

    <!--<FloatButtons @add="addNewTenant" />-->

    <!--TENANT SELECT FOR FETCHING DATA-->
    <v-select
      v-if="!onAddingProcess"
      label="Tenant"
      :items="tenants"
      v-model="selectedTenant"
      @change="getTenantDetail"
      item-text="name"
      item-value="id"
      solo
      required></v-select>

    <div>
      <!--WE COLLECT GENERAL SINGLE FORM INFORMATIONS UNDER INFORMATION COLLAPSE-->
      <InformationCollapse
        :onAddingProcess="onAddingProcess"
        :tenant="data"
        :currencies="currencies"
        :locales="locales" />
      <!--SILVERRAIL CREDENTIALS TABLE-->
      <SilverrailCredentials
        :onAddingProcess="onAddingProcess"
        :loading="dataLoader"
        :data="data.silverrailCredentials" />
      <!--SHIPPING CREDENTIALS TABLE-->
      <ShippingCredentials :tenant="data" :onAddingProcess="onAddingProcess" />
      <!--TAXI CREDENTIALS TABLE-->
      <TaxiCredentials :tenant="data" :onAddingProcess="onAddingProcess" />
      <!--KLARNA CREDENTIALS TABLE-->
      <KlarnaCredentials :onAddingProcess="onAddingProcess" :loading="dataLoader" :data="data.klarnaCredentials" />
      <!--SYSTEM OBJECTS-->
      <SystemObjects
        :onAddingProcess="onAddingProcess"
        :loading="dataLoader"
        :data="data.systems"
        :systemObjects="systemObjects" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import common from "@/mixins/common";
import InformationCollapse from "@/views/TenantManagement/components/InformationCollapse";
import KlarnaCredentials from "@/views/TenantManagement/components/KlarnaCredentials";
import SilverrailCredentials from "@/views/TenantManagement/components/SilverrailCredentials";
import ShippingCredentials from "@/views/TenantManagement/components/ShippingCredentials";
import TaxiCredentials from "@/views/TenantManagement/components/TaxiCredentials";
import SystemObjects from "@/views/TenantManagement/components/SystemObjects";
import { mapGetters } from "vuex";
import FloatButtons from "./components/FloatButtons.vue";

const initialData = {
  id: "00000000-0000-0000-0000-000000000000",
  name: "New Tenant",
  bookingAffiliateId: null,
  isOwnedByTrainPlanet: false,
  canSellCancellationProtection: false,
  canSellJourneys: false,
  canSellRailPassSeatReservations: false,
  canSellRailPasses: false,
  canSellTaxis: false,
  canUseGiftCards: false,
  canSellTicketsByInvoice: false,
  nationalIdNumberVerificationRequired: false,
  useMainTenantsNameForToSInEPSPayload: false,
  showNewsletterCheckboxInCheckout: false,

  emailCredentials: {
    fromAddress: "",
    fromName: "",
    host: "",
    port: 0,
    username: "",
  },
  klarnaCredentials: [],
  silverrailCredentials: [],
  shippingCredentials: [],
  taxiCredentials: [],
  theme: {
    mainColor: "#494968",
    secondaryColor: "#DB6484",
    headerColor: "#494968",
    headerTitleColor: "#FFFFFF",
    titleColor: "#FFFFFF",
    titleBackgroundColor: "#494968",
    mainColorSvgFilters: "invert(42%) sepia(79%) saturate(5881%) hue-rotate(346deg) brightness(92%) contrast(99%);",
    secondaryColorSvgFilters:
      "invert(42%) sepia(79%) saturate(5881%) hue-rotate(346deg) brightness(92%) contrast(99%);",
    mainPageUrl: "trainplanet.com",
    shopUrl: "tickets.trainplanet.com",
    logoUrl: "https://assets.trainplanet.com/logos/trainplanet-black.png",
    blackLogoUrl: "https://assets.trainplanet.com/logos/trainplanet-black.png",
    footerLogoUrl: "https://assets.trainplanet.com/logos/trainplanet-black.png",
    poweredByLogoUrl: "https://assets.trainplanet.com/email-resources/poweredby-white-header.png",
    giftCardBackgroundImageUrl: "https://assets.trainplanet.com/components/giftcards/gift-card.jpg",
    supportNumber: "0771 - 75 75 70",
    supportEmail: "support@trainplanet.com",
    customerPageUrl: "https://customer.trainplanet.com",
    termsAndConditionsPageUrl: "https://trainplanet.com/en/terms-and-conditions",
    logoWidth: "220px",
    loadingAnimationUrl: "https://assets.trainplanet.com/wlabel/trainplanet/animations/loading.gif",
    gtmId: "GTM-0000000",

    supportPageUrls: [
      {
        locale: "sv-SE",
        url: "",
      },
    ],
    groupOrderLinks: [
      {
        locale: "sv-SE",
        url: "",
      },
    ],
    navigationMenu: [
      {
        locale: "sv-SE",
        items: [
          {
            title: "",
            url: "",
            childItems: [
              {
                title: "",
                url: "",
              },
            ],
          },
        ],
      },
    ],
    languages: ["sv-SE"],
    currencies: ["SEK"],
    showCurrencyPicker: true,
    showNavigationMenu: true,
    toolbarOptions: {
      enabled: true,
      showContactButton: false,
      showCustomerPageButton: false,
      showFunkaButton: false,
      showMailButton: false,
      showPhoneButton: false,
      showSearchButton: false,
    },
    domains: [],
  },
  systems: [],
};

export default {
  mixins: [common],
  components: {
    Header,
    FloatButtons,
    SilverrailCredentials,
    KlarnaCredentials,
    InformationCollapse,
    SystemObjects,
    ShippingCredentials,
    TaxiCredentials,
  },
  data: () => ({
    onAddingProcess: false,
    selectedTenant: "",
    systemObjects: [],
    data: initialData,
    dataLoader: false,
    currencies: [],
    locales: [],
  }),
  async mounted() {
    this.loading = true;

    await Promise.all([this.getCurrenciesAndLocales(), this.getSystemObjects()]);

    this.loading = false;
  },
  watch: {
    tenants(val) {
      if (val.length > 0) {
        this.selectedTenant = this.tenants[0].id;
        this.getTenantDetail();
      }
    },
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
  },
  methods: {
    addNewTenant() {
      this.onAddingProcess = true;
      this.selectedTenant = "";
      this.data = initialData;
    },
    async getTenantDetail() {
      this.dataLoader = true;
      try {
        this.data = await this.api.getTenantDetail(this.selectedTenant);
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message);
      }
      this.dataLoader = false;
    },
    async getSystemObjects() {
      try {
        this.systemObjects = await this.api.getSystemObjects();
      } catch (error) {
        this.onError(error.response.data.message);
      }
    },
    async saveTenant() {
      this.pageLoader(true);
      try {
        let res;
        if (this.onAddingProcess) {
          res = await this.api.saveNewTenant(this.data);
          this.onAddingProcess = false;
        } else {
          res = await this.api.updateTenant(this.data);
        }
        this.data = res;
        this.onSuccess("You have successfully saved the tenant.");
      } catch (error) {
        this.onError(error.response.data.message);
      }
      this.pageLoader(false);
    },
    async getCurrenciesAndLocales() {
      const options = await this.api.getOrderSystemOptions();

      this.currencies = Object.keys(options.currencies);
      this.locales = Object.entries(options.locales).map(([value, text]) => ({
        value,
        text,
      }));
    },
  },
};
</script>

<style lang="scss">
.on-add {
  border: 3px solid #d0edda;
}
</style>
