<template>
  <v-data-table
    :headers="taxiHeaders"
    :items="tenant.taxiCredentials"
    class="elevation-1 my-7"
    :class="{ 'on-add': onAddingProcess }">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Taxi Credentials</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on"> New Item </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ editingItem ? "Edit" : "Create New" }} Item</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="modalPayload.environment"
                      :items="Environments"
                      menu-props="auto"
                      hide-details
                      item-text="text"
                      item-value="value"
                      label="Environment"
                      single-line></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field hide-details="auto" v-model="modalPayload.username" label="Username"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field hide-details="auto" v-model="modalPayload.password" label="Password"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="pr-8 pb-8">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = false"> Cancel </v-btn>
              <v-btn color="accent" @click="handleSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="deleteDialog = false">Cancel</v-btn>
              <v-btn color="accent" @click="confirmDeleteItem">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small color="third" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small color="primary" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import editableTableMixin from "@/views/TenantManagement/mixins/editableTableMixin";

export default {
  name: "TaxiCredentials",
  mixins: [editableTableMixin],
  props: {
    tenant: {
      type: Object,
      required: true,
    },
    onAddingProcess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taxiHeaders: [
        { text: "Environment", align: "start", sortable: false, value: "environment" },
        { text: "Username", value: "username" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      modalPayload: {
        environment: "",
        username: "",
        password: "",
      },
      dialog: false,
      deleteDialog: false,
      itemToDelete: null,
      editingItem: null,
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.modalPayload = {
          environment: "",
          username: "",
          password: "",
        };
        this.editingItem = null;
      }
    },
  },
  methods: {
    handleSave() {
      if (this.editingItem) {
        Object.assign(this.editingItem, {
          environment: this.modalPayload.environment,
          username: this.modalPayload.username,
          password: this.modalPayload.password,
        });
      } else {
        this.tenant.taxiCredentials.push({
          environment: this.modalPayload.environment,
          username: this.modalPayload.username,
          password: this.modalPayload.password,
        });
      }
      this.dialog = false;
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    confirmDeleteItem() {
      const index = this.tenant.taxiCredentials.indexOf(this.itemToDelete);
      if (index > -1) {
        this.tenant.taxiCredentials.splice(index, 1);
      }
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    editItem(item) {
      this.modalPayload.environment = item.environment;
      this.modalPayload.username = item.username;
      this.modalPayload.password = item.password;
      this.editingItem = item;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
