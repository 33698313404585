var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-7"
  }, [_c('v-expansion-panels', {
    class: {
      'on-add': _vm.onAddingProcess
    },
    attrs: {
      "accordion": "",
      "expansion-panel-border-radius": "30"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v("General Informations ")]), _c('v-expansion-panel-content', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.id,
      "label": "Tenant Id",
      "dense": "",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.tenant.id,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "id", $$v);
      },
      expression: "tenant.id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.name,
      "dense": "",
      "label": "Name",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.tenant.name,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "name", $$v);
      },
      expression: "tenant.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.bookingAffiliateId,
      "dense": "",
      "label": "Booking Affiliate ID",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.tenant.bookingAffiliateId,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "bookingAffiliateId", $$v);
      },
      expression: "tenant.bookingAffiliateId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Is owned by Trainplanet'
    },
    model: {
      value: _vm.tenant.isOwnedByTrainPlanet,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "isOwnedByTrainPlanet", $$v);
      },
      expression: "tenant.isOwnedByTrainPlanet"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Can Sell & Use Gift Cards'
    },
    model: {
      value: _vm.tenant.canUseGiftCards,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "canUseGiftCards", $$v);
      },
      expression: "tenant.canUseGiftCards"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Can Sell Journeys'
    },
    model: {
      value: _vm.tenant.canSellJourneys,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "canSellJourneys", $$v);
      },
      expression: "tenant.canSellJourneys"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Can Sell Rail Pass Seat Reservations'
    },
    model: {
      value: _vm.tenant.canSellRailPassSeatReservations,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "canSellRailPassSeatReservations", $$v);
      },
      expression: "tenant.canSellRailPassSeatReservations"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Can Sell Rail Passes'
    },
    model: {
      value: _vm.tenant.canSellRailPasses,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "canSellRailPasses", $$v);
      },
      expression: "tenant.canSellRailPasses"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Can Sell Tickets By Invoice'
    },
    model: {
      value: _vm.tenant.canSellTicketsByInvoice,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "canSellTicketsByInvoice", $$v);
      },
      expression: "tenant.canSellTicketsByInvoice"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Can Sell Taxis'
    },
    model: {
      value: _vm.tenant.canSellTaxis,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "canSellTaxis", $$v);
      },
      expression: "tenant.canSellTaxis"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Requires National ID Verification'
    },
    model: {
      value: _vm.tenant.nationalIdNumberVerificationRequired,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "nationalIdNumberVerificationRequired", $$v);
      },
      expression: "tenant.nationalIdNumberVerificationRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": "Display Main Tenant's Name in Terms & Conditions for EPS"
    },
    model: {
      value: _vm.tenant.useMainTenantsNameForToSInEPSPayload,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "useMainTenantsNameForToSInEPSPayload", $$v);
      },
      expression: "tenant.useMainTenantsNameForToSInEPSPayload"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "hide-details": "auto",
      "color": "greeny",
      "inset": "",
      "label": 'Show Newsletter Checkbox In Checkout'
    },
    model: {
      value: _vm.tenant.showNewsletterCheckboxInCheckout,
      callback: function ($$v) {
        _vm.$set(_vm.tenant, "showNewsletterCheckboxInCheckout", $$v);
      },
      expression: "tenant.showNewsletterCheckboxInCheckout"
    }
  })], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v("Email Credentials ")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "value": _vm.tenant.emailCredentials.fromAddress,
      "label": "From Address",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.emailCredentials.fromAddress,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.emailCredentials, "fromAddress", $$v);
      },
      expression: "tenant.emailCredentials.fromAddress"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "value": _vm.tenant.emailCredentials.fromName,
      "label": "From Name",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.emailCredentials.fromName,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.emailCredentials, "fromName", $$v);
      },
      expression: "tenant.emailCredentials.fromName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "value": _vm.tenant.emailCredentials.host,
      "label": "Host",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.emailCredentials.host,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.emailCredentials, "host", $$v);
      },
      expression: "tenant.emailCredentials.host"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.emailCredentials.username,
      "dense": "",
      "label": "Username",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.emailCredentials.username,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.emailCredentials, "username", $$v);
      },
      expression: "tenant.emailCredentials.username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.emailCredentials.port,
      "dense": "",
      "label": "Port",
      "hide-details": "auto",
      "outlined": "",
      "type": "number"
    },
    model: {
      value: _vm.tenant.emailCredentials.port,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.emailCredentials, "port", _vm._n($$v));
      },
      expression: "tenant.emailCredentials.port"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.emailCredentials.password,
      "dense": "",
      "label": "Password",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.emailCredentials.password,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.emailCredentials, "password", $$v);
      },
      expression: "tenant.emailCredentials.password"
    }
  })], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-subtitle-1 font-weight-bold mango-red"
  }, [_vm._v(" Theme Informations ")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.mainColor,
      "label": "Main Color",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.mainColor,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "mainColor", $$v);
      },
      expression: "tenant.theme.mainColor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.secondaryColor,
      "label": "Secondary Color",
      "dense": "",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.secondaryColor,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "secondaryColor", $$v);
      },
      expression: "tenant.theme.secondaryColor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.headerColor,
      "label": "Header Color",
      "dense": "",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.headerColor,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "headerColor", $$v);
      },
      expression: "tenant.theme.headerColor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.headerTitleColor,
      "label": "Header Title Color",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.headerTitleColor,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "headerTitleColor", $$v);
      },
      expression: "tenant.theme.headerTitleColor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.titleColor,
      "label": "Title Color",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.titleColor,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "titleColor", $$v);
      },
      expression: "tenant.theme.titleColor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.titleBackgroundColor,
      "label": "Title Background Color",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.titleBackgroundColor,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "titleBackgroundColor", $$v);
      },
      expression: "tenant.theme.titleBackgroundColor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.mainColorSvgFilters,
      "label": "Main Color Svg Filters",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.mainColorSvgFilters,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "mainColorSvgFilters", $$v);
      },
      expression: "tenant.theme.mainColorSvgFilters"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.secondaryColorSvgFilters,
      "label": "Secondary Color Svg Filters",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.secondaryColorSvgFilters,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "secondaryColorSvgFilters", $$v);
      },
      expression: "tenant.theme.secondaryColorSvgFilters"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.mainPageUrl,
      "label": "Main Page URL",
      "dense": "",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.mainPageUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "mainPageUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.mainPageUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.shopUrl,
      "label": "Shop URL",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.shopUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "shopUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.shopUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.customerPageUrl,
      "label": "Customer Page URL",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.customerPageUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "customerPageUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.customerPageUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.supportNumber,
      "label": "Support Number",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.supportNumber,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "supportNumber", $$v);
      },
      expression: "tenant.theme.supportNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.supportEmail,
      "label": "Support Email",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.supportEmail,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "supportEmail", $$v);
      },
      expression: "tenant.theme.supportEmail"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.termsAndConditionsPageUrl,
      "label": "ToS Page URL",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.termsAndConditionsPageUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "termsAndConditionsPageUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.termsAndConditionsPageUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.logoUrl,
      "label": "Logo URL",
      "dense": "",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.logoUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "logoUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.logoUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.blackLogoUrl,
      "label": "Black Logo URL",
      "dense": "",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.blackLogoUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "blackLogoUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.blackLogoUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.logoWidth,
      "label": "Logo Width",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.logoWidth,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "logoWidth", $$v);
      },
      expression: "tenant.theme.logoWidth"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.gtmId,
      "label": "GTM ID",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.gtmId,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "gtmId", $$v);
      },
      expression: "tenant.theme.gtmId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.loadingAnimationUrl,
      "label": "Loading Animation URL",
      "dense": "",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.loadingAnimationUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "loadingAnimationUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.loadingAnimationUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.footerLogoUrl,
      "label": "Footer Logo URL",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.footerLogoUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "footerLogoUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.footerLogoUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.poweredByLogoUrl,
      "label": "Powered By Logo URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.poweredByLogoUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "poweredByLogoUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.poweredByLogoUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.favIconUrl,
      "label": "Fav Icon URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.favIconUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "favIconUrl", $$v);
      },
      expression: "tenant.theme.favIconUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.favIcon16Url,
      "label": "Fav Icon 16x16 URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.favIcon16Url,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "favIcon16Url", $$v);
      },
      expression: "tenant.theme.favIcon16Url"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.favIcon32Url,
      "label": "Fav Icon 32x32 URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.favIcon32Url,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "favIcon32Url", $$v);
      },
      expression: "tenant.theme.favIcon32Url"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.appleTouchIconUrl,
      "label": "Apple Touch Icon URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.appleTouchIconUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "appleTouchIconUrl", $$v);
      },
      expression: "tenant.theme.appleTouchIconUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.maskIconUrl,
      "label": "Mask Icon URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.maskIconUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "maskIconUrl", $$v);
      },
      expression: "tenant.theme.maskIconUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.webManifestUrl,
      "label": "Web Manifest URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.webManifestUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "webManifestUrl", $$v);
      },
      expression: "tenant.theme.webManifestUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.browserConfigUrl,
      "label": "Browser Config URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.browserConfigUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "browserConfigUrl", $$v);
      },
      expression: "tenant.theme.browserConfigUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.fontCssUrl,
      "label": "Font CSS URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.fontCssUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "fontCssUrl", $$v);
      },
      expression: "tenant.theme.fontCssUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.tenant.theme.giftCardBackgroundImageUrl,
      "label": "Gift Card Background Image URL",
      "hide-details": "auto",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.tenant.theme.giftCardBackgroundImageUrl,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "giftCardBackgroundImageUrl", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "tenant.theme.giftCardBackgroundImageUrl"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "outlined": "",
      "items": _vm.locales,
      "chips": "",
      "item-value": "value",
      "item-text": "text",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select languages",
      "multiple": "",
      "hide-details": "",
      "persistent-hint": false
    },
    model: {
      value: _vm.tenant.theme.languages,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "languages", $$v);
      },
      expression: "tenant.theme.languages"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "outlined": "",
      "items": _vm.currencies,
      "chips": "",
      "item-value": "id",
      "item-text": "name",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select currencies",
      "multiple": "",
      "hide-details": "",
      "persistent-hint": false
    },
    model: {
      value: _vm.tenant.theme.currencies,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "currencies", $$v);
      },
      expression: "tenant.theme.currencies"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    staticClass: "mt-5 ml-10",
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Currency Picker',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.showCurrencyPicker,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "showCurrencyPicker", $$v);
      },
      expression: "tenant.theme.showCurrencyPicker"
    }
  })], 1)], 1), _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "2"
    }
  }, [_c('h3', [_vm._v("Toolbar Options")])]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": "Show Toolbar",
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "enabled", $$v);
      },
      expression: "tenant.theme.toolbarOptions.enabled"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Contact Button',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.showContactButton,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "showContactButton", $$v);
      },
      expression: "tenant.theme.toolbarOptions.showContactButton"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Customer Page Button',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.showCustomerPageButton,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "showCustomerPageButton", $$v);
      },
      expression: "tenant.theme.toolbarOptions.showCustomerPageButton"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Funka Button',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.showFunkaButton,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "showFunkaButton", $$v);
      },
      expression: "tenant.theme.toolbarOptions.showFunkaButton"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Mail Button',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.showMailButton,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "showMailButton", $$v);
      },
      expression: "tenant.theme.toolbarOptions.showMailButton"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Phone Button',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.showPhoneButton,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "showPhoneButton", $$v);
      },
      expression: "tenant.theme.toolbarOptions.showPhoneButton"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-switch', {
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Search Button',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.toolbarOptions.showSearchButton,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme.toolbarOptions, "showSearchButton", $$v);
      },
      expression: "tenant.theme.toolbarOptions.showSearchButton"
    }
  })], 1)], 1)], 1), _c('div', [_c('v-row', [_c('h3', {
    staticClass: "ml-3 mt-6"
  }, [_vm._v("Support Page")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-3 mb-4 mt-5 third white--text",
    on: {
      "click": _vm.addItemsupport
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _c('span', [_vm._v("Add Item")])], 1)], 1), _vm._l(_vm.tenant.theme.supportPageUrls, function (supportPageUrl, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "cols": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "3",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-select', {
      staticClass: "align-stretch",
      attrs: {
        "items": _vm.locales,
        "label": "Support Page language",
        "hide-details": "auto",
        "item-value": "value",
        "item-text": "text",
        "outlined": ""
      },
      model: {
        value: supportPageUrl.locale,
        callback: function ($$v) {
          _vm.$set(supportPageUrl, "locale", $$v);
        },
        expression: "supportPageUrl.locale"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "9",
        "md": "12",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "rules": [_vm.rules.required],
        "label": "Support Page URL",
        "hide-details": "auto",
        "dense": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "append-outer",
        fn: function () {
          return [_c('v-btn', {
            staticClass: "mt-2 mr-lg-n3 mt-lg-n2",
            attrs: {
              "color": "red",
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.removeItemsupport(index);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: supportPageUrl.url,
        callback: function ($$v) {
          _vm.$set(supportPageUrl, "url", $$v);
        },
        expression: "supportPageUrl.url"
      }
    })], 1)], 1);
  })], 2), _c('div', [_c('v-row', [_c('h3', {
    staticClass: "ml-3 mt-6"
  }, [_vm._v("Group Order")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-3 mb-4 mt-5 third white--text",
    on: {
      "click": _vm.addItem
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _c('span', [_vm._v("Add Item")])], 1)], 1), _vm._l(_vm.tenant.theme.groupOrderLinks, function (groupOrderLinks, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "cols": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-select', {
      attrs: {
        "items": _vm.locales,
        "label": "Group Order Links language",
        "hide-details": "auto",
        "item-value": "value",
        "item-text": "text",
        "outlined": ""
      },
      model: {
        value: groupOrderLinks.locale,
        callback: function ($$v) {
          _vm.$set(groupOrderLinks, "locale", $$v);
        },
        expression: "groupOrderLinks.locale"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "9"
      }
    }, [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "rules": [_vm.rules.required],
        "label": "Group Order Links URL",
        "hide-details": "auto",
        "dense": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "append-outer",
        fn: function () {
          return [_c('v-btn', {
            staticClass: "mt-2 mr-lg-n3 mt-lg-n2",
            attrs: {
              "color": "red",
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.removeItem(index);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: groupOrderLinks.url,
        callback: function ($$v) {
          _vm.$set(groupOrderLinks, "url", $$v);
        },
        expression: "groupOrderLinks.url"
      }
    })], 1)], 1);
  })], 2), _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h3', {
    staticClass: "mt-3 mb-3"
  }, [_vm._v("Navigation Menu")])]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-switch', {
    staticClass: "mt-3 mb-3",
    attrs: {
      "color": "greeny",
      "inset": "",
      "label": 'Show Navigation Menu',
      "hide-details": "auto",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.tenant.theme.showNavigationMenu,
      callback: function ($$v) {
        _vm.$set(_vm.tenant.theme, "showNavigationMenu", $$v);
      },
      expression: "tenant.theme.showNavigationMenu"
    }
  })], 1)], 1), _c('div', [_c('v-tabs', {
    staticClass: "mb-8",
    model: {
      value: _vm.activeNavigationMenuIndex,
      callback: function ($$v) {
        _vm.activeNavigationMenuIndex = $$v;
      },
      expression: "activeNavigationMenuIndex"
    }
  }, [_vm._l(_vm.tenant.theme.navigationMenu, function (navigationMenu, navigationMenuIndex) {
    return _c('v-tab', {
      key: navigationMenuIndex,
      staticClass: "ml-2"
    }, [_vm._v(" " + _vm._s(_vm.locales.length > 0 && _vm.locales.find(function (locale) {
      return locale.value === navigationMenu.locale;
    }).text) + " "), _c('v-btn', {
      attrs: {
        "color": "red",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeNavigationMenu(navigationMenuIndex);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-delete")])], 1)], 1);
  }), _c('div', {
    staticClass: "mt-2"
  }, [_c('v-select', {
    staticClass: "mb-4 ml-7 pa-0",
    staticStyle: {
      "max-width": "max-content"
    },
    attrs: {
      "label": "Add New Translation",
      "items": _vm._f("notIn")(_vm.locales, _vm.locales, _vm.tenant.theme.navigationMenu),
      "hide-details": "auto",
      "item-value": "value",
      "item-text": "text",
      "persistent-hint": false
    },
    on: {
      "change": _vm.addNavigationMenu
    }
  })], 1), _c('v-tabs-items', [_c('v-row', {}, [_c('h3', {
    staticClass: "mt-7 ml-3"
  }, [_vm._v("Items")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mt-5 mb-3 mr-3 white--text",
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.addNavigationMenuItem(_vm.activeNavigationMenuIndex);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _c('span', {
    staticClass: "mr-7 ml-9"
  }, [_vm._v("Add Item")])], 1)], 1), _vm._l(_vm.tenant.theme.navigationMenu[_vm.activeNavigationMenuIndex].items, function (item, navigationMenuItemIndex) {
    return _c('div', {
      key: navigationMenuItemIndex,
      staticClass: "mb-3"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-text-field', {
      staticClass: "mt-5",
      attrs: {
        "rules": [_vm.rules.required],
        "label": "Item Title",
        "hide-details": "auto",
        "dense": "",
        "outlined": ""
      },
      model: {
        value: item.title,
        callback: function ($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1), _c('v-col', {
      staticClass: "mt-5",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-text-field', {
      staticClass: "",
      attrs: {
        "rules": [_vm.rules.required],
        "label": "Item URL",
        "hide-details": "auto",
        "dense": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "append-outer",
        fn: function () {
          return [_c('v-btn', {
            staticClass: "mt-2 mr-lg-n3 mt-lg-n2",
            attrs: {
              "color": "red",
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.removeNavigationMenuItem(_vm.activeNavigationMenuIndex, navigationMenuItemIndex);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.url,
        callback: function ($$v) {
          _vm.$set(item, "url", $$v);
        },
        expression: "item.url"
      }
    })], 1)], 1), _c('v-row', {
      staticClass: "ml-8"
    }, [_c('h4', {
      staticClass: "ml-15 mt-1"
    }, [_vm._v("Child Items")]), _c('v-spacer'), _c('v-btn', {
      staticClass: "text-none pa-3 mr-3 mb-2 third white--text",
      on: {
        "click": function ($event) {
          return _vm.addNavigationMenuItemChildItem(_vm.activeNavigationMenuIndex, navigationMenuItemIndex);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "dark": ""
      }
    }, [_vm._v("mdi-playlist-plus")]), _c('span', [_vm._v("Add Child Item")])], 1)], 1), _vm._l(item.childItems, function (childItem, navigationMenuItemChildItemIndex) {
      return _c('v-row', {
        key: navigationMenuItemChildItemIndex,
        staticClass: ""
      }, [_c('v-col', {
        staticClass: "mb-1 pa-1",
        attrs: {
          "cols": "1"
        }
      }), _c('v-col', {
        staticClass: "mb-1 ml-lg-n8 pa-1",
        attrs: {
          "cols": "4"
        }
      }, [_c('v-text-field', {
        staticClass: "",
        attrs: {
          "rules": [_vm.rules.required],
          "label": "Child Item Title",
          "hide-details": "auto",
          "dense": "",
          "outlined": ""
        },
        model: {
          value: childItem.title,
          callback: function ($$v) {
            _vm.$set(childItem, "title", $$v);
          },
          expression: "childItem.title"
        }
      })], 1), _c('v-col', {
        staticClass: "mb-1 pa-1",
        attrs: {
          "cols": "7"
        }
      }, [_c('v-text-field', {
        staticClass: "ml-5",
        attrs: {
          "rules": [_vm.rules.required],
          "label": "Child Item URL",
          "hide-details": "auto",
          "dense": "",
          "outlined": ""
        },
        scopedSlots: _vm._u([{
          key: "append-outer",
          fn: function () {
            return [_c('v-btn', {
              staticClass: "mt-lg-n2 mr-lg-n10",
              attrs: {
                "color": "red",
                "icon": ""
              },
              on: {
                "click": function ($event) {
                  return _vm.removeNavigationMenuItemChildItem(_vm.activeNavigationMenuIndex, navigationMenuItemIndex, navigationMenuItemChildItemIndex);
                }
              }
            }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
          },
          proxy: true
        }], null, true),
        model: {
          value: childItem.url,
          callback: function ($$v) {
            _vm.$set(childItem, "url", $$v);
          },
          expression: "childItem.url"
        }
      })], 1)], 1);
    })], 2);
  })], 2)], 2)], 1)], 1), _c('div', [_c('v-row', [_c('h3', {
    staticClass: "ml-3 mt-6 mr-3"
  }, [_vm._v("Domains")]), _c('v-tooltip', {
    staticStyle: {
      "max-width": "10px"
    },
    attrs: {
      "top": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('div', {
    staticStyle: {
      "max-width": "300px"
    }
  }, [_vm._v(" These domains are used to detect a tenant's identity by domain. There should be no 'https://', no 'www' or no ending '/' at the end. Example: 'trainplanet.com' (no quotes) ")])]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-3 mb-4 mt-5 third white--text",
    on: {
      "click": _vm.addDomain
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _c('span', [_vm._v("Add Domain")])], 1)], 1), _vm._l(_vm.tenant.theme.domains, function (domain, domainIndex) {
    return _c('v-row', {
      key: domainIndex
    }, [_c('v-col', [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "rules": [_vm.rules.required],
        "label": "Domain URL",
        "hide-details": "auto",
        "dense": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "append-outer",
        fn: function () {
          return [_c('v-btn', {
            staticClass: "mt-2 mr-lg-n3 mt-lg-n2",
            attrs: {
              "color": "red",
              "icon": ""
            },
            on: {
              "click": function ($event) {
                return _vm.removeDomain(domainIndex);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.tenant.theme.domains[domainIndex],
        callback: function ($$v) {
          _vm.$set(_vm.tenant.theme.domains, domainIndex, $$v);
        },
        expression: "tenant.theme.domains[domainIndex]"
      }
    })], 1)], 1);
  })], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }